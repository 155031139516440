.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.actions::after {
  content: "\2807";
  font-size: 3em;
  color: #2e2e2e;
}
.buttonWhite {
  border-radius: 40px;
  height: 38px;
  border: 1px solid blue;
  background-color: #fff;
  color: blue;
  margin: 10px;
}

.buttonBlue {
  border-radius: 40px;
  height: 38px;
  border: 1px solid blue;
  background-color: blue;
  color: white;
  margin: 10px;
}

.bottom-line {
  border-bottom: 5px solid silver;
}
.labels {
  font-family: Gilroy-Medium;
  font-size: 16px;
  color: #444444;
}

.button {
  height: 50px;
  border-radius: 4px;
  border-color: rgb(0, 90, 146);
  background: rgb(0, 90, 146);
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.verticalLine {
  border-left: 1px solid rgba(15, 18, 32, 0.1);
  width: 2px;
  height: 5vh;
  margin-left: 20px;
  margin-right: 20px;
}
.textStyleInput {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  color: #444444;
  /* border: 1px solid #f2f2f2; */
  border: 1px solid #c7c7c7;
}
.textStyleInputDisable {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #f2f2f2;
  background-color: rgb(199, 199, 199);
}
.text-style-select {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #f2f2f2;
}

.checkboxDiv {
  margin-top: 20px;
  display: flex;
}

.objectionEnabledCheckbox {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
}
.checkboxLegalRights {
  align-self: center;
  color: rgb(0, 90, 146);
}

.checkboxObjectionEnabled {
  align-self: center;
  color: rgb(0, 90, 146);
  cursor: pointer;
}

.legalRights {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  font-style: normal;
  letter-spacing: 0;
  color: rgb(0, 90, 146);
}

.objectionEnabled {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  font-style: normal;
  letter-spacing: 0;
  color: rgb(0, 90, 146);
}

.saveParkingZoneButton {
  justify-content: center;
  align-self: center;
}
.modalForm {
  margin-top: 20px;
}

.test {
  display: flex;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  position: relative;
  align-items: center;
}
.test .dropdown-menu {
  position: absolute !important;
  top: 100% !important; /* Ensures it appears right below */
  left: 0;
  z-index: 1000;
  transform: none !important;
}
.inactive {
  color: gray;
}
.active {
  color: green;
}

input[type="file"] {
  display: none;
}

.active {
  background-color: "red";
}

.modal__headerLine {
  position: absolute;
  width: 10px;
  height: 4px;
  background-color: #c7c7c7;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  top: 35px;
  left: 0px;
}

.modal__title {
  font-size: 24px;
  font-family: Gilroy-Bold;
  padding-bottom: 14px;
}

.addEntityButton {
  border: none;
  background-color: white;
}
/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align icon */
.left-addon .glyphicon {
  left: 0px;
}
.right-addon .glyphicon {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}
input[type="date"]:required:invalid::-webkit-datetime-edit {
  visibility: hidden;
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  margin-right: 15px;
}
.fuel-checkbox-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 3px; 
}
.nav{
  padding-right: 30px;
  padding-left: 30px;
}